var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-form',{ref:"form",attrs:{"readonly":_vm.readonly,"show-error":false,"input-align":"right","error-message-align":"right"},on:{"submit":_vm.onSubmit}},[_c('van-cell-group',{attrs:{"inset":"","border":false}},[_c('van-field',{attrs:{"readonly":"","label":"日期","value":_vm.timeFormat(_vm.result.execDate)}}),_c('van-field',{staticClass:"checkPlan",attrs:{"label":"实施方案","required":"","name":"plan","rules":[
                  {
                      validator: function () { return !!Object.keys(_vm.detailsMap).length; },
                      message: '请选择至少一个方案',
                  } ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('checkout-group',{attrs:{"type":"tag","value":Object.keys(_vm.detailsMap),"disabled":_vm.readonly,"list":_vm.mode,"indeterminate":false},on:{"click-option":_vm.clickOption}})]},proxy:true}])}),_c('van-collapse',{attrs:{"accordion":"","border":false},model:{value:(_vm.collapseActive),callback:function ($$v) {_vm.collapseActive=$$v},expression:"collapseActive"}},_vm._l((_vm.detailsMap),function(value,key){return _c('van-collapse-item',{key:key,attrs:{"title":_vm.modeMap[key].name,"name":key,"value":_vm.validatePlanDetail(_vm.detailsMap[key]) ? '待完善' : ''}},[_c('plan-module',{attrs:{"readonly":_vm.readonly,"data":_vm.detailsMap[key]},on:{"update:data":function($event){return _vm.$set(_vm.detailsMap, key, $event)},"selectPosition":function($event){return _vm.$router.push(("/hoss/txMap?storageKey=address-" + key))}}})],1)}),1),_c('van-field',{attrs:{"label":"睡眠时长","placeholder":"请输入","rules":[
                  { required: true, message: '请输入睡眠时长' },
                  { validator: _vm.duration, message: '请输入正确时长，最多一位小数' } ],"required":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v("小时")]},proxy:true}]),model:{value:(_vm.result.sleep),callback:function ($$v) {_vm.$set(_vm.result, "sleep", $$v)},expression:"result.sleep"}}),_c('van-field',{attrs:{"label":"静态心率","placeholder":"请输入","required":"","type":"number","maxlength":"10","rules":[
                  { required: true, message: '请输入静态心率' },
                  { pattern: /^\d+$/, message: '请输入正确心率，不包含小数' } ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v("BPM")]},proxy:true}]),model:{value:(_vm.result.heartRate),callback:function ($$v) {_vm.$set(_vm.result, "heartRate", $$v)},expression:"result.heartRate"}}),_c('van-field',{attrs:{"label":"体重","required":"","type":"number","placeholder":"请输入","maxlength":"10","rules":[
                  { required: true, message: '请输入体重' },
                  { validator: _vm.num1, message: '请输入正确体重，最多一位小数' } ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v("KG")]},proxy:true}]),model:{value:(_vm.result.weightKg),callback:function ($$v) {_vm.$set(_vm.result, "weightKg", $$v)},expression:"result.weightKg"}}),_c('van-field',{staticClass:"my-textarea",attrs:{"label":"总结","required":"","autosize":{ maxHeight: 300, minHeight: 100 },"rules":[{ required: true, message: '请输入总结' }],"type":"textarea","maxlength":"300","placeholder":"请输入","show-word-limit":""},model:{value:(_vm.result.detail),callback:function ($$v) {_vm.$set(_vm.result, "detail", $$v)},expression:"result.detail"}}),_c('van-field',{staticClass:"my-textarea",attrs:{"label":"风险提示","autosize":{ maxHeight: 300, minHeight: 100 },"type":"textarea","maxlength":"300","placeholder":"请输入","show-word-limit":""},model:{value:(_vm.result.riskStatement),callback:function ($$v) {_vm.$set(_vm.result, "riskStatement", $$v)},expression:"result.riskStatement"}}),_c('van-field',{staticClass:"upload-field",attrs:{"required":"","rules":[{ required: true, message: '请选择图片或视频进行上传' }],"label":"图片和视频"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('my-uploader',{attrs:{"disabled":_vm.readonly},model:{value:(_vm.result.picUrl),callback:function ($$v) {_vm.$set(_vm.result, "picUrl", $$v)},expression:"result.picUrl"}})]},proxy:true}])})],1),_c('div',{staticClass:"btn-group"},[_c('van-button',{attrs:{"color":"var(--secondMainColor)","native-type":"button"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" 返回 ")]),(!_vm.readonly)?_c('van-button',{attrs:{"color":"var(--mainColor)","native-type":"submit","loading":_vm.submitting}},[_vm._v(" 提交 ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }