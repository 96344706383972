<template>
    <div>
        <van-field label="方式" required label-width="70px">
            <van-radio-group
                slot="input"
                v-model="moduleData.execWay"
                :disabled="readonly"
                icon-size="18px"
                direction="horizontal"
            >
                <van-radio :name="1">现场实施</van-radio>
                <van-radio :name="2">远程实施</van-radio>
            </van-radio-group>
        </van-field>

        <van-field
            :value="moduleData.address"
            :required="moduleData.execWay !== 2"
            is-link
            label="实施地址"
            @click="readonly ? '' : $emit('selectPosition')"
        />

        <van-field
            readonly
            required
            clickable
            label="时间"
            placeholder="过程总时长"
            :value="timeFormat(moduleData.execDuration)"
            @click="readonly ? '' : (timePicker = true)"
        />
        <van-popup v-model="timePicker" round position="bottom">
            <van-datetime-picker
                type="time"
                :min-hour="0"
                :formatter="formatter"
                @cancel="timePicker = false"
                @confirm="onConfirm"
            />
        </van-popup>

        <template v-if="moduleData.type == 'sports'">
            <van-cell required style="padding-bottom: 5px"> 运动方式 </van-cell>
            <sports :sportsList.sync="moduleData.sportsList" :readonly="readonly" />
        </template>

        <van-field
            v-model="moduleData.detail"
            label="详情"
            required
            type="textarea"
            maxlength="300"
            show-word-limit
            placeholder="请输入"
            :autosize="{ maxHeight: 80, minHeight: 40 }"
            class="my-textarea"
        />

        <van-field label="评分" required>
            <van-rate
                v-model="moduleData.rating"
                :readonly="readonly"
                :size="25"
                slot="input"
                color="#ffd21e"
            />
        </van-field>
    </div>
</template>

<script>
import Sports from "./Sports.vue";
export default {
    components: { Sports },
    props: ["data", "readonly"],
    data() {
        return {
            timePicker: false,
        };
    },
    computed: {
        moduleData: {
            get() {
                return this.data;
            },
            set(val) {
                console.log(val, `111`);
                this.$emit("update:data", val);
            },
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name == "TxMap") {
                let location = "";
                const data = sessionStorage.getItem(this.mapStorageKey);
                if (data) {
                    const { ad_info, title } = JSON.parse(data);
                    location = `${ad_info.city}·${title}`;
                    sessionStorage.removeItem(this.mapStorageKey);
                }
                if (location) vm.execPlace = location;
            } else {
                vm.init();
            }
        });
    },
    methods: {
        // 选择时间
        onConfirm(e) {
            const time = e.split(":");
            const second = time[0] * 3600 + time[1] * 60;
            this.timePicker = false;
            this.moduleData.execDuration = second;
        },

        formatter(type, value) {
            return value * 1 + (type == "hour" ? " 小时" : " 分钟");
        },

        timeFormat(time) {
            if (time === 0) {
                return "0小时0分钟";
            } else if (!time) {
                return "";
            } else {
                const h = parseInt((time / 60 / 60) % 24);
                const m = parseInt((time / 60) % 60);
                return `${h}小时${m}分钟`;
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.van-cell {
    background-color: transparent;
}
.van-radio {
    font-size: 13px;
}
</style>
