<template>
  <van-form
      :readonly="readonly"
      ref="form"
      :show-error="false"
      input-align="right"
      error-message-align="right"
      @submit="onSubmit"
  >
    <van-cell-group inset :border="false">
      <!-- 选择日期 -->
      <van-field readonly label="日期" :value="timeFormat(result.execDate)"/>
      <!-- 选择方案 -->
      <van-field
          label="实施方案"
          required
          name="plan"
          :rules="[
                    {
                        validator: () => !!Object.keys(detailsMap).length,
                        message: '请选择至少一个方案',
                    },
                ]"
          class="checkPlan">
        <template #input>
          <checkout-group
              type="tag"
              :value="Object.keys(detailsMap)"
              :disabled="readonly"
              :list="mode"
              :indeterminate="false"
              @click-option="clickOption"
          />
        </template>
      </van-field>

      <!-- 方案详情 -->
      <van-collapse v-model="collapseActive" accordion :border="false">
        <van-collapse-item
            v-for="(value, key) in detailsMap"
            :key="key"
            :title="modeMap[key].name"
            :name="key"
            :value="validatePlanDetail(detailsMap[key]) ? '待完善' : ''"
        >
          <plan-module
              :readonly="readonly"
              :data.sync="detailsMap[key]"
              @selectPosition="$router.push(`/hoss/txMap?storageKey=address-${key}`)"
          />
        </van-collapse-item>
      </van-collapse>

      <!-- 总结 -->
      <van-field
          label="睡眠时长"
          v-model="result.sleep"
          placeholder="请输入"
          :rules="[
                    { required: true, message: '请输入睡眠时长' },
                    { validator: duration, message: '请输入正确时长，最多一位小数' },
                ]"
          required
      >
        <template #button>小时</template>
      </van-field>
      <van-field
          v-model="result.heartRate"
          label="静态心率"
          placeholder="请输入"
          required
          type="number"
          maxlength="10"
          :rules="[
                    { required: true, message: '请输入静态心率' },
                    { pattern: /^\d+$/, message: '请输入正确心率，不包含小数' },
                ]"
      >
        <template #button>BPM</template>
      </van-field>
      <van-field
          v-model="result.weightKg"
          label="体重"
          required
          type="number"
          placeholder="请输入"
          maxlength="10"
          :rules="[
                    { required: true, message: '请输入体重' },
                    { validator: num1, message: '请输入正确体重，最多一位小数' },
                ]"
      >
        <template #button>KG</template>
      </van-field>
      <van-field
          v-model="result.detail"
          label="总结"
          required
          :autosize="{ maxHeight: 300, minHeight: 100 }"
          :rules="[{ required: true, message: '请输入总结' }]"
          type="textarea"
          maxlength="300"
          placeholder="请输入"
          show-word-limit
          class="my-textarea"
      />
      <van-field
          v-model="result.riskStatement"
          label="风险提示"
          :autosize="{ maxHeight: 300, minHeight: 100 }"
          type="textarea"
          maxlength="300"
          placeholder="请输入"
          show-word-limit
          class="my-textarea"
      />
      <van-field
          class="upload-field"
          required
          :rules="[{ required: true, message: '请选择图片或视频进行上传' }]"
          label="图片和视频"
      >
        <template #input>
          <my-uploader v-model="result.picUrl" :disabled="readonly"/>
        </template>
      </van-field>
    </van-cell-group>
    <div class="btn-group">
      <van-button color="var(--secondMainColor)" native-type="button" @click="$router.back()">
        返回
      </van-button>
      <van-button
          v-if="!readonly"
          color="var(--mainColor)"
          native-type="submit"
          :loading="submitting"
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import MyUploader from "@/components/MyUploader";
import CheckoutGroup from "@/components/checkbox/CheckoutGroup.vue";
import PlanModule from "./PlanModule.vue";
import {timeFormat} from "@/utils";
import {duration, num1} from "@/utils/validate";
import {getResultDetail, saveResultDetail} from "@/api/hoss";
import {CloudSaveStatus} from "@/api/consts";

export default {
  components: {CheckoutGroup, MyUploader, PlanModule},
  data() {
    return {
      readonly: false,
      submitting: false,
      isLoadFinished: false,
      // 方案列表
      mode: [],
      modeMap: {},
      // 折叠面板
      collapseActive: "",

      // 方案数据
      detailsMap: {},
      // 表单数据
      result: {},
    };
  },
  async created() {
    const {mode} = await this.$getDict();
    this.mode = mode.children;
    this.modeMap = {};
    this.mode.forEach((item) => {
      this.modeMap[item.code] = item;
    });

    // if (this.readonly) {
    //   this.$emit("changeTitleBarComponent", {
    //         name: "CloudSaveStatusView",
    //         params: {
    //           status: CloudSaveStatus.SUCCESS,
    //           isEdit: !this.readonly,
    //           type: "Normal",
    //           happenedTimeMs: Date.now()
    //         }
    //       }
    //   );
    // }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (from.name != "TxMap") {
        await vm.getDetail();
      } else {
        let address = "";
        // 获取传入地图页面 storage 的 key
        const {storageKey} = from.query;
        const type = storageKey?.split("-")?.[1];
        if (!type) return;
        const data = sessionStorage.getItem(storageKey);
        if (data) {
          const {ad_info, title} = JSON.parse(data);
          address = `${ad_info.city}·${title}`;
          sessionStorage.removeItem(storageKey);
          vm.detailsMap[type].address = address;
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isLoadFinished) {
      next();
      return;
    }
    if (this.result.state != 1 && to.name != "TxMap") this.saveDetail(0);
    next();
  },
  methods: {
    duration,
    num1,
    timeFormat,
    // 选择地址
    selectPosition(type) {
      this.$router.push(`/hoss/txMap?storageKey=address-${type}`);
    },
    // 验证 + 保存 实施记录
    async onSubmit() {
      for (const key in this.detailsMap) {
        const element = this.detailsMap[key];
        const res = this.validatePlanDetail(element);
        if (res) return; // 在验证失败 并且 正在提交的时候退出保存
      }
      try {
        this.submitting = true;
        await this.saveDetail(1);
        console.log("保存成功");
        this.$router.back();
      } catch (error) {
        console.log(error);
      }
      this.submitting = false;
    },
    // 保存实施记录
    async saveDetail(state) {
      console.log("submit");
      const details = Object.values(this.detailsMap);
      const result = {...this.result, state};
      this.result = result;
      const {deleteDetails, deleteSport} = this.findArrDiff(
          details,
          this.lastSubmitDetails
      );
      await saveResultDetail({
        details,
        result,
        deleteSport,
        deleteDetails,
      });
    },
    // 获取实施记录
    async getDetail() {
      this.resetData();
      const {id, milepostId, date} = this.$route.query;
      let currentTimestamp = null;
      if (date) {
        // 当前时间的时间戳 ms
        currentTimestamp = date;
      }
      try {
        const {value} = await getResultDetail({
          id: id,
          milepostId: milepostId,
          date: currentTimestamp
        });
        const {details, result} = value;
        details.forEach((item) => {
          this.$set(this.detailsMap, item.type, item);
        });
        this.result = {...this.result, ...result};
        this.lastSubmitDetails = value.details;
        this.readonly = !!this.result.state;
        this.isLoadFinished = true;
      } catch (e) {
        console.log(e);
        // 返回上一页
        this.$router.back();
      }
      console.log("实施记录created", this.readonly);
      if (this.readonly) {
        this.$emit("changeTitleBarComponent", {
              name: "CloudSaveStatusView",
              params: {
                status: CloudSaveStatus.SUCCESS,
                isEdit: !this.readonly,
                type: "Normal",
                happenedTimeMs: Date.now()
              }
            }
        );
      }
    },
    // 点击实施方案
    clickOption({item, isSelect}) {
      if (isSelect) {
        this.$delete(this.detailsMap, item.code);
      } else {
        this.$set(this.detailsMap, item.code, {
          type: item.code,
          address: "", // 实施地址
          detail: "", // 详情
          execDuration: "", // 实施时长
          execWay: "", // 实施方式
          rating: 0, // 评分 };
          sportsList: [],
        });
      }
      this.$refs.form.validate("plan");
    },
    // 重置数据
    resetData() {
      this.result = {
        execDate: "",
        sleep: "",
        heartRate: "",
        weightKg: "",
        detail: "",
        riskStatement: "",
        picUrl: "",
      };
      this.detailsMap = {};
    },

    // 找出新旧计划详情删除的模块和运动方式
    findArrDiff(newDetailVal, oldDetailVal) {
      const deleteDetails = [];
      const deleteSport = [];
      const {detailIds: newDetailIds, sportIds: newSportIds} = this.findIds(newDetailVal);
      const {detailIds: oldDetailIds, sportIds: oldSportIds} = this.findIds(oldDetailVal);

      oldDetailIds.forEach((item) => {
        if (!newDetailIds.includes(item)) {
          deleteDetails.push(item);
        }
      });
      oldSportIds.forEach((item) => {
        if (!newSportIds.includes(item)) {
          deleteSport.push(item);
        }
      });

      return {deleteDetails, deleteSport};
    },
    // 找到模块内全部id，运动模块内运动列表全部id
    findIds(details) {
      this.detailTypes = [];
      const detailIds = [];
      const sportIds = [];
      details.forEach((detail) => {
        if (detail.id) {
          detailIds.push(detail.id);
          if (detail.type == "sports") {
            detail.sportsList.forEach((sport) => {
              if (sport.id) sportIds.push(sport.id);
            });
          }
        }
      });
      return {detailIds, sportIds};
    },
    // 验证某个计划模块
    validatePlanDetail(item) {
      // console.log("validatePlanDetail", item);
      if (!item) return true;
      if (!item.execWay) return true;
      if (item.execWay === 2) {
        return false;
      } else {
        if (!item.address) return true;
      }
      if (!item.detail) return true;
      if (!item.execDuration && item.execDuration !== 0) return true;
      if (item.type === "sports" && !item.sportsList.length) return true;
      if (!item.rating) return true;
      return false;
    },
    onTitleBarNeedTransferData(data) {
      console.log("onTitleBarNeedTransferData", data);
      if (data === 'changeToEdit') {
        this.readonly = false;
        this.$emit("changeTitleBarComponent", {
              name: "CloudSaveStatusView",
              params: {
                status: CloudSaveStatus.SUCCESS,
                isEdit: !this.readonly,
                type: "Normal",
                happenedTimeMs: Date.now()
              }
            }
        );
      } else if (data === "needSave") {
        this.onSubmit();
      }
    },
  },
};
</script>

<style scoped lang="less">
.van-form {
  margin-bottom: 54px;

  .van-cell-group {
    margin: 10px;
    border-radius: 10px;
  }

  .upload-field.van-field {
    display: block;

    /deep/ .van-field__label {
      width: 100%;

      &::after {
        content: "（如上传视频控制在1分钟内）";
        color: var(--subTitleColor);
        font-size: 12px;
      }
    }
  }

  .btn-group {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;

    .van-button {
      flex: 1 0 50%;
      border-radius: 0;
    }
  }
}

.checkPlan.van-field {
  flex-direction: column;

  /deep/ .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .checkbox {
      flex: 0 0 23%;
      margin: 8px 0 0;
      height: 30px;
    }
  }
}

/deep/ .van-collapse {
  margin: 0 16px;
  border-radius: 4px;
  overflow: hidden;

  .van-collapse-item__title,
  .van-collapse-item__content {
    background: var(--secondCardBackgroundColor);
  }

  .van-collapse-item__title .van-cell__value {
    font-size: 12px;
    color: #ee0a24;
  }
}
</style>
