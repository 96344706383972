<template>
    <div>
        <van-cell-group :border="false" inset class="sport-list">
            <van-cell v-for="(sport, index) in sportsList" :key="index" class="sport-item">
                <span>{{ sport.typeName }}</span>
                <span class="quantityMotion">{{ sport.quantityMotion }}</span>
                <van-icon
                    v-if="!readonly"
                    name="delete-o"
                    color="var(--red)"
                    size="20px"
                    @click="delSport({ sport, index })"
                />
            </van-cell>
            <van-cell
                v-if="!readonly"
                :border="false"
                class="add-sport"
                @click="addSportPopup = true"
            >
                添加运动
                <van-icon name="plus" style="margin-left: 5px" />
            </van-cell>
        </van-cell-group>

        <!-- 添加运动 -->
        <van-popup v-model="addSportPopup" round position="bottom">
            <p class="pop-title">添加运动</p>
            <!-- 选择运动方式 -->
            <van-field
                input-align="left"
                :value="sportItem.typeName"
                readonly
                is-link
                label="方式"
                placeholder="选择运动方式"
                @click="sportListPicker = true"
            />

            <!-- 填写运动量 -->
            <van-field
                input-align="left"
                label="运动量"
                v-model="sportItem.quantityMotion"
                placeholder="请输入"
                maxlength="300"
            />

            <div class="btn-group">
                <van-button native-type="button" @click="cancel">取消</van-button>
                <van-button color="var(--mainColor)" native-type="button" @click="addSport">
                    提交
                </van-button>
            </div>
        </van-popup>
        <van-popup v-model="sportListPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="sportColumns"
                @cancel="sportListPicker = false"
                @confirm="selectSport"
            />
        </van-popup>
    </div>
</template>

<script>
export default {
    props: ["sportsList", "readonly"],
    data() {
        return {
            addSportPopup: false,
            sportListPicker: false,
            sportColumns: [],

            sportItem: {
                typeName: "",
                sportType: "",
                quantityMotion: "",
            },
        };
    },
    computed: {
        list: {
            get() {
                return this.sportList;
            },
            set(val) {
                this.$emit("update:sportList", val);
            },
        },
    },
    async created() {
        const dict = await this.$getDict();
        this.sportColumns = dict.sportsType.children.map((item) => {
            return { text: item.name, value: item.code };
        });
    },
    methods: {
        // 添加一条新的运动
        addSport() {
            if (!this.sportItem.sportType || !this.sportItem.quantityMotion) {
                return this.$toast("请完善运动信息");
            } else {
                this.sportsList.push({
                    quantityMotion: this.sportItem.quantityMotion,
                    sportType: this.sportItem.sportType,
                    typeName: this.sportItem.typeName,
                });
                this.cancel();
            }
        },

        // 删除一条运动
        delSport({ index }) {
            this.sportsList.splice(index, 1);
        },

        // 关闭
        cancel() {
            this.sportItem.typeName = "";
            this.sportItem.sportType = "";
            this.sportItem.quantityMotion = "";
            this.addSportPopup = false;
        },

        //
        selectSport(e) {
            this.sportItem.typeName = e.text;
            this.sportItem.sportType = e.value;
            this.sportListPicker = false;
        },
    },
};
</script>

<style scoped lang="less">
.pop-title {
    text-align: center;
    color: var(--subTitleColor);
}
.btn-group {
    width: 100%;
    display: flex;
    .van-button {
        flex: 1;
        border-radius: 0;
    }
}

.sport-list {
    background-color: var(--background);
    .add-sport .van-cell__value {
        text-align: center;
    }
    .sport-item .van-cell__value {
        display: flex;
        align-items: center;
        .quantityMotion {
            flex: 1;
            text-align: right;
            margin-right: 5px;
        }
    }
}
.van-dialog /deep/.van-dialog__content {
    padding: 15px 0;
}
</style>
